import * as React from "react"
import { flexRender, type Table as TanstackTable } from "@tanstack/react-table"

import { getCommonPinningStyles } from "~/lib/data-table"
import { ny as cn } from "~/lib/utils"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "~/components/ui/table"
import { DataTablePagination } from "~/components/data-table/data-table-pagination"
import { useAppSelector } from "~/lib/hooks"
import { Skeleton } from "@chakra-ui/react"

interface DataTableProps<TData> extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The table instance returned from useDataTable hook with pagination, sorting, filtering, etc.
   * @type TanstackTable<TData>
   */
  table: TanstackTable<TData>

  /**
   * The floating bar to render at the bottom of the table on row selection.
   * @default null
   * @type React.ReactNode | null
   * @example floatingBar={<TasksTableFloatingBar table={table} />}
   */
  floatingBar?: React.ReactNode | null

  onCellClick?: (data: any) => void
}

export function DataTable<TData>({
  table,
  floatingBar = null,
  children,
  className,
  onCellClick,
  ...props
}: DataTableProps<TData>) {
  const logs = useAppSelector((state) => state.app.logs.list);
  return (
    <div
      className={cn("w-full space-y-2.5 overflow-auto", className)}
      {...props}
    >
      {children}
      <div className="overflow-hidden rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        ...getCommonPinningStyles({ column: header.column }),
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  className={`hover:bg-blue-50/60 ${onCellClick ? "cursor-pointer" : ''}`}
                  onClick={(e) => {
                    console.log(e.target)
                    // @ts-ignore
                    if (e.target.getAttribute('role') !== "menuitem") {
                      onCellClick?.(row.original)
                    }
                  }}
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      style={{
                        ...getCommonPinningStyles({ column: cell.column }),
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : logs.state === "loading" ? [...Array(18)].map((e: any) => {
              return ( <TableRow>
               <TableCell ><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell ><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4} rounded={8} /></TableCell>
               <TableCell><Skeleton h={4}  rounded={8} /></TableCell>
               <TableCell><Skeleton h={4}  rounded={8} /></TableCell>
               <TableCell><Skeleton h={4}  rounded={8} /></TableCell>
           </TableRow>)
            }) : (
              <TableRow>
                <TableCell
                  colSpan={table.getAllColumns().length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex flex-col gap-2.5">
        <DataTablePagination table={table} />
        {table.getFilteredSelectedRowModel().rows.length > 0 && floatingBar}
      </div>
    </div>
  )
}
