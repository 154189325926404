"use client"

import * as React from "react"
import { DotsHorizontalIcon } from "@radix-ui/react-icons"
import { type ColumnDef } from "@tanstack/react-table"
import { toast } from "sonner"

import { getErrorMessage } from "~/lib/handle-error"
import { formatDate } from "~/lib/utils"
import { Badge } from "~/components/ui/badge"
import { Button } from "~/components/ui/button"
import { Checkbox } from "~/components/ui/checkbox"
import { Box, HStack, IconButton, Skeleton, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { Edit2Icon, PauseIcon, PlayIcon, Trash2Icon, TrashIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu"
import { DataTableColumnHeader } from "~/components/data-table/data-table-column-header"
import { ZapIcon } from "lucide-react"
import _ from 'lodash'
import { useAppDispatch } from "~/lib/hooks"
import { rerunAnalytics } from "~/lib/app/logs/thunk"


const AudioPlayer = ({ url }: { url: string }) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      // Update duration when metadata is loaded
      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
      });

      // Update progress as the audio plays
      audio.addEventListener("timeupdate", () => {
        setProgress(audio.currentTime);
      });
    }

    return () => {
      if (audio) {
        audio.removeEventListener("loadedmetadata", () => {});
        audio.removeEventListener("timeupdate", () => {});
      }
    };
  }, []);

  const togglePlayPause = (ev: any) => {
    ev.preventDefault()
    ev.stopPropagation()
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleSliderChange = (value: number) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = value;
      setProgress(value);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  return (
    <Box width="200px" bg="gray.100" borderRadius="lg">
      <audio ref={audioRef} src={url} />

      <HStack>
        <IconButton
        size={"xs"}
          aria-label={isPlaying ? "Pause" : "Play"}
          icon={isPlaying ? <PauseIcon size={12} /> : <PlayIcon size={12} />}
          onClick={togglePlayPause}
        />
        <Text>{formatTime(progress)}</Text>

        <Slider
          aria-label="audio-progress"
          value={progress}
          min={0}
          onClick={(e) => {
            e.stopPropagation()
          }}
          max={duration}
          onChange={handleSliderChange}
          flex="1"
          focusThumbOnChange={false}
        >
          <SliderTrack bg="gray.300">
            <SliderFilledTrack bg="blue.500" />
          </SliderTrack>
          <SliderThumb />
        </Slider>
        <Text>{formatTime(duration)}</Text>
      </HStack>
    </Box>
  );
};

export function getColumns(): ColumnDef<any>[] {

  return [
    {
      id: "id",
      accessorKey: "id",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Identifier" />
      ),
      cell: ({ row }) => <div>{row.getValue("id")}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "number",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Phone Number" />
      ),
      enableSorting: false,
      enableHiding: false,
      // cell: ({ row }) => {
      //   const meta: any = row.getValue("meta")

      //   return (
      //     <div className="flex space-x-2">
      //       {meta.type ? <Badge variant="outline">{meta.type}</Badge> : <></>}
      //     </div>
      //   )
      // },
    },
    {
      accessorKey: "email",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Email Address" />
      ),
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: "contact_type",
      enableSorting: false,
      enableHiding: false,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Type" />
      ),
      cell: ({ row }) => {
        // if (!priority) return null

        // const Icon = getPriorityIcon(priority)

        return (
          <div className="flex items-center">
            {/* <Icon
              className="mr-2 size-4 text-muted-foreground"
              aria-hidden="true"
            /> */}
            <span className="capitalize">{_.capitalize(row.getValue("contact_type"))}</span>
          </div>
        )
      },
      filterFn: (row, id, value) => {
        return Array.isArray(value) && value.includes(row.getValue(id))
      },
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Created At" />
      ),
      cell: ({ cell }) => formatDate(cell.getValue() as Date),
    },
    {
      id: "actions",
      cell: function Cell({ row }) {
        const dispatch = useAppDispatch()
        const toast = useToast()
        const [isUpdatePending, startUpdateTransition] = React.useTransition()
        const [showUpdateTaskSheet, setShowUpdateTaskSheet] =
          React.useState(false)
        const [showDeleteTaskDialog, setShowDeleteTaskDialog] =
          React.useState(false)
        const payload: any = row.original
        const agent: string = payload["agent_id"]
        const id: string = payload["id"]

        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  aria-label="Open menu"
                  variant="ghost"
                  className="flex size-8 p-0 data-[state=open]:bg-muted"
                >
                  <DotsHorizontalIcon className="size-4" aria-hidden="true" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-40">
                <DropdownMenuItem onSelect={(e) => {
                  e.stopPropagation()
                  // dispatch(rerunAnalytics(id, agent))
                  // toast({
                  //   title: "Analytics retriggered",
                  //   status: "info"
                  // })
                }}>
                  <Edit2Icon size={16} className="mr-2" /> Update
                </DropdownMenuItem>
                <DropdownMenuItem className="text-red-500 hover:text-red-600" onSelect={(e) => {
                  e.stopPropagation()
                  // dispatch(rerunAnalytics(id, agent))
                  // toast({
                  //   title: "Analytics retriggered",
                  //   status: "info"
                  // })
                }}>
                  <Trash2Icon size={16} className="mr-2" /> Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </>
        )
      },
      size: 40,
    },
  ]
}
