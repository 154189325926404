import { Alert, Avatar, Box, Button, Center, Divider, Flex, IconButton, Image, Img, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import Vapi from "@vapi-ai/web";
import Lottie from "lottie-react";
import { MicIcon, MicOffIcon, PhoneOffIcon } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import Millis from '@millisai/web-sdk';
import { useSearchParams } from "react-router-dom";
import AIAnimation from "../../assets/animation/ai.json"
import { axios } from "../../utils/axios.loader";
import Logo from "../../assets/img/logo-full.png";
import Markdown from "react-markdown";
import mixpanel from "mixpanel-browser";
import _ from 'lodash';

const formatStringWithData = (template: string, data: any) => {
  const regex = /\{([^}]+)\}/g;
  const matches: any = template.matchAll(regex)
  let temp = template
  for (const match of matches) {
    temp = temp.replaceAll(match[0], _.get(data, match[1]) || '')
  }
  return temp
}

export default function Demo ({metadata}: { metadata?: any }) {
    const [hasCallStarted, setHasCallStarted] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const vapi = useRef<Vapi | undefined>();
    const millis = useRef<any>();
    const [params] = useSearchParams()
    const [time, setTime] = useState(0);
    const [type, setType] = useState<"vapi" | "millis">()
    const timer = useRef<NodeJS.Timeout | number | undefined>();
    const [isTestLoading, setTestLoading] = useState(false);
    const toast = useToast()
    const [assistant, setAssistantDetails] = useState<any>({})
    const [organization, setOrganizationDetails] = useState<any>({})
    const [hasDisconnected, setHasDisconnected] = useState(false)
    const [callMetadata, setCallMetadata] = useState({})

    const getLeadMetadata = async (assistant: string) => {
      const identifier = params.get("lead_id")  || params.get("user_phone_number") || params.get("identifier") 
        let call_metadata = {}
        if (identifier) {
          try {
            const request = await axios.get(`/agent/${assistant}/call-metadata?identifier=${identifier}`, {
                headers: {
                    Authorization: `Bearer demo-key`
                }
            })
            const { data } = request.data
            call_metadata = data || {}
            call_metadata = {
              ...(call_metadata),
              ...((call_metadata as any).mandateInfo || {})
            }
            delete (call_metadata as any).mandateInfo
            setCallMetadata(data)
          } catch (err) {
            console.warn("Failed to fetch call metadata")
          }
        }
        return call_metadata
    }

    const getAssistantDetails = async () => {
        const response = await axios.get(`/agent/${params.get("assistantId")}`, {
            headers: {
                Authorization: `Bearer demo-key`
            }
        })
        setAssistantDetails(response.data)
        if (response.data.id) {

        getLeadMetadata(response.data.id)
        }
    }

    const getOrganizationDetails = async (org: string) => {
      const response = await axios.get(`/organization/${org}`, {
          headers: {
              Authorization: `Bearer demo-key`
          }
      })
      setOrganizationDetails(response.data)
  }

    useEffect(() => {
        getAssistantDetails()
      }, []);

    
      useEffect(() => {
        if (assistant.organization_id) {
          getOrganizationDetails(assistant.organization_id)
        }
      }, [assistant])


      const initiateCall =  useCallback(async () => {
        setHasDisconnected(false)
        setTestLoading(true);
        if (!hasCallStarted) {
          if (type === "vapi") {
            await vapi.current?.start(
              params.get("agent")!,
            );
          } else if (type === "millis") {
            const metadata = { ...callMetadata, ...((callMetadata as any)?.mandateInfo || {}) }
            delete metadata.mandateInfo
            await millis.current?.start(
              params.get("agent")!,
              {
                ...(metadata || {}),
                ...(Object.fromEntries(params.entries() || new Map()))
              },
              true
            );
          }
        }
      }, [ type, millis, vapi, hasCallStarted, callMetadata ])

    useEffect(() => {
      if (assistant?.id) {
        const provider = assistant?.provider?.toLowerCase()
        setType(provider)
        if (provider === "vapi") {
          if (!vapi.current) {
            if (params.get("key")) {
              vapi.current = new Vapi(
                  params.get("key")!
              );
              vapi.current.on("call-end", () => {
                if (timer.current !== null) {
                  clearInterval(timer.current);
                }
                setHasCallStarted(false);
              });
              vapi.current?.on("call-start", () => {
                  setHasCallStarted(true);
                  setTestLoading(false);
                    toast({
                      title: `Call started. You can now speak with ${
                        assistant?.name || ""
                      }`,
                    });
                    if (!timer.current) {
                      timer.current = setInterval(() => {
                        setTime((v) => v + 1);
                      }, 1000);
                    }
              })
            }
          }
        } else if (provider === "millis") {
          if (!millis.current) {
            if (params.get("key")) {
              millis.current = Millis.createClient({
                publicKey: params.get("key")!,
                debug: true,
                endPoint: "wss://api-eu-west.millis.ai/millis",
              });
              millis.current.on("onclose", () => {
                if (timer.current !== null) {
                  clearInterval(timer.current);
                }
                setHasCallStarted(false);
              });
              millis.current?.on("onready", () => {
                console.log("Call ready")
                setHasCallStarted(true);
                setTestLoading(false);
                toast({
                  title: `Call started. You can now speak with ${
                    assistant?.name || ""
                  }`,
                });
                if (!timer.current) {
                  timer.current = setInterval(() => {
                    setTime((v) => v + 1);
                  }, 1000);
                }
              })
            }
          }
        }
        if (provider) {
          // initiateCall()
        }
      }
    }, [assistant, initiateCall ])

      useEffect(() => {
        if (vapi.current) {
            if (type === "vapi") {
            }
        } else if (millis.current) {
          if (type === "millis") {
          }
        }
      }, [vapi, millis])

    return (
        <Box pos={"relative"}>
            <Box
                zIndex={4}
                backdropFilter={"blur(5px)"}
                background={"transparent"}
                display={"block"}
                pos={"absolute"}
                w={"100vw"}
                overflow={"hidden"}
                h={"calc(100vh - 54px)"}
            >
                <Center flexDirection={"column"} overflow={"hidden"} height={["100vh", null, "auto"]}>
                     {assistant?.meta?.title ? <Stack
                     boxShadow={"md"}
                     gap={0}
                     overflow={["scroll", null, "auto"]}
                          width={["full", null, "60vw"]}
                          backgroundColor={"white"}
                          padding={[4, null, 8]}
                          borderRadius="10px"
                          alignItems="center" // Center everything inside the Stack
                          spacing={2}
                      >
                      <Image h={16} src={formatStringWithData(assistant?.meta?.logo || '', callMetadata)} alt="Company Logo" />
                      <Divider my={3} />
                      <Text mb={2} fontSize="lg" fontWeight="bold">
                        {formatStringWithData(assistant?.meta?.title || '', callMetadata)}
                      </Text>
                      <Text mb={2} fontSize="md" color="gray.600">
                        {formatStringWithData(assistant?.meta?.subtitle || '', callMetadata)}
                      </Text>
                        <Box
                          backgroundColor="white"
                          borderRadius="10px"
                          padding={[0, null, 4]}
                          width="100%"
                        >
                        <Alert status='success' variant='left-accent' alignItems="start" borderRadius="10px" backgroundColor={"#f8f9fa"} borderLeftColor="blue.500">
                          <Stack spacing={2}>
                            <Text color={"#0073e6"} fontWeight="bold" fontSize="lg" mb={4} >{formatStringWithData(assistant?.meta?.description, callMetadata)}</Text>
                            {(assistant?.meta?.instructions || []).map((e: string) =>  <Markdown skipHtml={false}>{formatStringWithData(e, callMetadata)}</Markdown>)}
                          </Stack>
                        </Alert>
                        </Box>
                        <Avatar size={"lg"} mb={3} src={assistant?.profile_pic} name={assistant?.name} />
                        <Text textAlign={"center"} my={3} fontWeight={"bold"} fontSize={"xl"}>{hasCallStarted && !hasDisconnected ? "You are now talking to" : "Talk to"} {assistant?.name}</Text>
                       <Flex bg={"white"} zIndex={99999} pos={["fixed", "relative"]} bottom={0} left={0} right={0} p={[0, 0]} justifyContent={"center"} alignItems={"center"} width="100%">
                        {!hasCallStarted ? <Button 
                            colorScheme="blue"
                            color={"white"} 
                            h={"48px !important"}
                            rounded={[0, 6]}
                            
                            w={["full", "fit-content"]}
                            leftIcon={<MicIcon size={20} />}
                            size={["md", null, "lg"]}
                            isLoading={isTestLoading || !assistant?.id} 
                            onClick={async () => {
                              mixpanel.track("START_CALL", Object.fromEntries(params.entries()))
                              await initiateCall()
                            }}
                          >
                            Start Now
                          </Button>: !hasDisconnected ? <Flex
                          h={"64px"}
                          gap={3}
                                alignItems={"center"}
                            >
                                <IconButton
                                onClick={() => {
                                    if (isMuted) {
                                    vapi?.current?.setMuted(false);
                                    setIsMuted(false);
                                    } else {
                                    vapi?.current?.setMuted(true);
                                    setIsMuted(true);
                                    }
                                }}
                                variant={"outline"}
                                border={"1px solid #cacaca"}
                                colorScheme={isMuted ? "primary" : "none"}
                                rounded={"full"}
                                icon={isMuted ? <MicOffIcon size={20} /> : <MicIcon size={20} />}
                                aria-label=""
                                />
                                <Text fontWeight={700}>{`${String(
                                Math.floor((time % 3600) / 60)
                                ).padStart(2, "0")}:${String(time % 60).padStart(2, "0")}`}</Text>
                                <IconButton
                                colorScheme="red"
                                onClick={() => {
                                  setHasDisconnected(true)
                                  if (type === "vapi") {
                                    vapi.current?.stop();
                                  } else {
                                    millis.current?.stop()
                                  }
                                    if (timer.current !== null) {
                                    clearInterval(timer.current);
                                    }
                                    setHasCallStarted(false);
                                }}
                                rounded={"full"}
                                icon={<PhoneOffIcon size={20} />}
                                aria-label=""
                                />
                            </Flex> : <></>}
                        </Flex>
                        <Flex w={"full"} mt={3} justifyContent={"center"} gap={3} alignItems={"center"} background={"#e6f7ff"} p={4} rounded={6}>
                        <Box h={"24px"} w={"24px"} borderRadius={"full"}>
                        <svg className="blinking-dot">
        <circle fill="#008000" stroke="none" cx="12" cy="12" r="6">
          <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1"></animate>
        </circle>
      </svg>
      </Box>  <Text fontSize={"sm"} fontWeight={"semibold"} color={"#0073e6"}>You're in great company! {Math.ceil(Math.random() * 10).toFixed()} other candidates are currently taking the AI screening right now.</Text>
                        </Flex>
                    </Stack> : !hasDisconnected ? <> {organization?.id && organization?.image_url ? <Image h={20} mb={4} src={organization?.image_url} />  : organization?.id ? <Image mb={4} w={250} src={Logo} /> : <></>}
                    <Box cursor={"pointer"}>
                    <Box 
                        height={250}
                        width={250}>
                        <Lottie
                        animationData={AIAnimation}
                    />
                    </Box>
                    </Box>
                    <Avatar size={"sm"} src={assistant?.profile_pic} name={assistant?.name} />
                    <Text mt={2} fontWeight={600}>You are now talking with {assistant?.name}</Text>
                    <Text>{assistant?.organization?.name}</Text>
                    {params.get("candidate_number") ? <Text mt={2} fontWeight={600}>Candidate Number: {params.get("candidate_number")}</Text> : <></>}
                    {params.get("user_phone_number") ? <Text mt={2} fontWeight={600}>Phone Number: {params.get("user_phone_number")}</Text> : <></>}
                    {!hasCallStarted ? <Button mt={4} rounded={"full"} isLoading={isTestLoading || !assistant?.id} onClick={async () => {
                      mixpanel.track("START_CALL", Object.entries(params.entries()))
                      await initiateCall()
                    }} colorScheme="primary">Start call</Button> : !hasDisconnected ? <Flex
                    alignItems={"center"}
                    zIndex={4}
                    gap={6}
                    rounded={36}
                    background={"white"}
                    display={hasCallStarted ? "flex" : "none"}
                    bottom={12}
                    py={2}
                    px={2}
                    border={"1px solid #cacaca"}
                >
                    <IconButton
                    onClick={() => {
                        if (isMuted) {
                        vapi?.current?.setMuted(false);
                        setIsMuted(false);
                        } else {
                        vapi?.current?.setMuted(true);
                        setIsMuted(true);
                        }
                    }}
                    variant={"outline"}
                    border={"1px solid #cacaca"}
                    colorScheme={isMuted ? "primary" : "none"}
                    rounded={"full"}
                    icon={isMuted ? <MicOffIcon size={20} /> : <MicIcon size={20} />}
                    aria-label=""
                    />
                    <Text fontWeight={700}>{`${String(
                    Math.floor((time % 3600) / 60)
                    ).padStart(2, "0")}:${String(time % 60).padStart(2, "0")}`}</Text>
                    <IconButton
                    colorScheme="red"
                    onClick={() => {
                      setHasDisconnected(true)
                      if (type === "vapi") {
                        vapi.current?.stop();
                      } else {
                        millis.current?.stop()
                      }
                        if (timer.current !== null) {
                        clearInterval(timer.current);
                        }
                        setHasCallStarted(false);
                    }}
                    rounded={"full"}
                    icon={<PhoneOffIcon size={20} />}
                    aria-label=""
                    />
                </Flex> : <></>}
                    </> : <Stack alignItems={"center"}>
                      <Image h={100} src="https://i.guim.co.uk/img/static/sys-images/Guardian/Pix/pictures/2015/4/21/1429629869293/d8e257bb-a76d-49e7-989a-39e07196a915-2060x1236.jpeg?width=620&quality=85&auto=format&fit=max&s=bcefd8888dd640e9225928efda75f2cd" />
                      <Text size={"xl"} fontWeight={600}>Thank you. Have a great day</Text>
                      </Stack>}
                </Center>
            </Box>
        </Box>
    )
}