import { AbsoluteCenter, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, Collapse, Divider, Flex, IconButton, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Stack, Step, StepIndicator, Stepper, StepSeparator, StepTitle, Text } from "@chakra-ui/react";
import { MailIcon, PauseIcon, PhoneCallIcon, PlayIcon, SparklesIcon, SubtitlesIcon } from "lucide-react";
import moment from "moment-timezone";
import { RefObject, useEffect, useRef, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";
import Page from "~/@components/Page";
import { getContact, getContactInteractions } from "~/lib/app/contacts/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";

function TableAudio({
    url,
    onInteraction,
    call
  }: {
    url: string;
    onInteraction: (
      state: "playing" | "paused",
      ref?: RefObject<HTMLAudioElement> | null
    ) => void,
    call: any
  }) {
    const [duration, setDuration] = useState(0);
    const [track, setTrack] = useState(0);
    const [isPaused, setPaused] = useState(true);
    const ref = useRef<HTMLAudioElement>(null);
    const [isSummaryShown, setSummaryShown] = useState<boolean>(true)
    const [isTranscriptShown, setTranscriptShown] = useState<boolean>(false)

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };
  
    return (
        <>
        <Flex w={"100%"} borderRadius={12} alignItems={"center"} py={4}  px={3} gap={2}>
            <audio
                onEnded={() => {
                    onInteraction("paused", ref);
                    setTrack(0);
                    setPaused(true);
                }}
                onTimeUpdate={(e) => {
                    console.log(e.currentTarget.currentTime)
                    setTrack(Math.ceil(e.currentTarget.currentTime));
                }}
                hidden
                ref={ref}
                src={url}
                onLoadedMetadata={(e) => {
                    setDuration(Math.ceil(ref.current?.duration || 0));
                }}
            />
                <IconButton onClick={() => {
                    setPaused((state) => !state)
                    if (isPaused) {
                        ref?.current?.play()
                    } else {
                        ref?.current?.pause()
                    }
                }} aria-label="" icon={isPaused ? <PlayIcon size={16} /> : <PauseIcon size={16} />} />
                <Slider value={track} min={0} max={duration} w={"100%"} isReadOnly={true} aria-label='slider-ex-4'  defaultValue={0}>
                    <SliderTrack bg='#282828'>
                        <SliderFilledTrack  />
                    </SliderTrack>
                    <SliderThumb ml={3} borderRadius={2} w={2} h={4}>

                    </SliderThumb>
                </Slider>
                <Text fontSize={"sm"} color={"#9A9A9A"}>{formatTime(duration)}</Text>
                <Button onClick={() => {
                    setTranscriptShown(state => !state)
                }} ml={3} fontSize={"sm"} colorScheme="primary" fontWeight={500} px={8} leftIcon={<SubtitlesIcon />}>Transcript</Button>
                <Box onClick={() => {
                    setSummaryShown((state) => !state)
                }} borderStyle={"solid"} borderWidth={1} style={{
                    borderImageSlice: 1
                }} rounded={"full"} >
                    <IconButton background={"#FAFAFA"} rounded={"full"} aria-label="" icon={<SparklesIcon size={16} />} />
                </Box>
            </Flex>
            <Collapse in={isSummaryShown}>
            <Box borderStyle={"solid"} borderRadius={12} borderWidth={1} style={{
                borderImageSlice: 1
            }} >
                <Stack pos={"relative"} px={3} py={4}  borderRadius={12}>
                    <Box pos={"absolute"} top={2} right={2}>
                        <SparklesIcon size={20} />
                    </Box>
                    <Text fontWeight={"bold"}>Summary</Text>
                    <Markdown>{call.summary || "No summary available"}</Markdown>
                </Stack>
            </Box>
            </Collapse>
            <Collapse in={isTranscriptShown}>
            <Box borderStyle={"solid"} borderRadius={12} borderWidth={1} style={{
                borderImageSlice: 1
            }} >
                <Stack pos={"relative"} px={3} py={4}  borderRadius={12}>
                    <Box pos={"absolute"} top={2} right={2}>
                        <SparklesIcon size={20} />
                    </Box>
                    <Text fontWeight={"bold"}>Transcript</Text>
                    {(call?.messages || []).filter((d: any) => !["tool_calls", "tool_call_result"].includes(d.role)).filter((v: any) => v.role !== "system").map((message: any) => (
                        <Text fontSize={"sm"}>
                          <Text fontWeight={600} as={"span"}>{["bot", "assistant", "ai"].includes(message.role) ? "AI" : "User"}: </Text>
                          <Text as={"span"}>{message.message || message.content}</Text>
                        </Text>
                      ))}
                      {(call?.messages || []).filter((d: any) => !["tool_calls", "tool_call_result"].includes(d.role)).length === 0 ? <Text fontSize={"sm"}>No Transcript</Text> : <></>}
                </Stack>
            </Box>
            </Collapse>
        </>
    );
  }

export default function SpecificContact() {
    const [show, setShow] = useState(false)
    const dispatch = useAppDispatch()
    const _contact = useAppSelector<any>(state => state.app.contacts.get.data)
    const interactions = useAppSelector<any>(state => state.app.contacts.interactions)
    const { organization, contact } = useParams()
 
    useEffect(() => {
        dispatch(getContact(organization!, contact!))
        dispatch(getContactInteractions(organization!, contact!))
    }, [])

    return (
        <Page showBack title={`Interactions with ${_contact.first_name || ''} ${_contact.last_name || ''}`}>
            <Stack borderTop={"1px solid #DADADA"}>
            <Flex gap={6} mt={4} px={6} pt={2} borderRadius={16} pos={"relative"} >
                    <Stack gap={6} flex={0.4}>
                        <Box>
                            <Text fontWeight={"bold"} fontSize={"lg"} >{_contact.first_name || ''} {_contact.last_name || ''}</Text>
                            <Text>{_contact.number}</Text>
                        </Box>
                        <Box>
                            <Text fontWeight={"bold"} fontSize={"lg"} >{"Overall Summary"}</Text>
                            <Text color={"#363636"}>{_contact?.summary}</Text>
                        </Box>
                    </Stack>
                    <Stack gap={4} maxH={'calc(100vh - 100px)'} overflow={"scroll"} flex={0.6}>
                        <Text fontWeight={500} fontSize={"lg"} >{interactions.metadata?.total_count || 0} Interactions</Text>
                        <Stepper colorScheme='white' size='xs' index={-1} orientation='vertical' minH='200px' gap='0'>
                            {(interactions.data || []).map((e: any) => {
                                return (
                                    <Step style={{ width: "95%" }} key={0}>
                                        <StepIndicator>
                                        </StepIndicator>
                                        <Box flexShrink='0'  w={"100%"}>
                                            <StepTitle >{moment(e.created_at).format("MMMM DD, YYYY")}</StepTitle>
                                            <Stack  w={"100%"} my={3} py={0} px={3} borderRadius={12}>
                                                {e.interaction_type === "CALL" ? <TableAudio url={e.meta?.recordingUrl} onInteraction={() => {

                                                }} call={e} /> : e.interaction_type === "WHATSAPP" ? <Accordion w={"calc(45vw - 48px)"} allowMultiple={true} allowToggle={true} >
                                                <AccordionItem border={0}>
                                                    {({ isExpanded }) => (
                                                        <>
                                                            <h2>
                                                                <AccordionButton py={0} px={1} w={"100%"} outline={0} border={0}>
                                                                    <Flex w={"full"} gap={2} alignItems={"center"} flex='1' textAlign='left'>
                                                                        {!isExpanded ? <Flex alignItems={"center"} flex={1} justifyContent={"space-between"} gap={2}>
                                                                            {/* <Avatar src={agents[0].image} boxSize={8} borderRadius={2} /> */}
                                                                            <Text fontSize={14} color={'#C2C2C2'} noOfLines={1} flex={1}>
                                                                                {e.messages?.[0] ? e.messages?.[0]?.content : "No messages available" }
                                                                            </Text>
                                                                            <Flex alignItems={"center"} gap={2}>
                                                                                <FaWhatsapp size={20} color="#9A9A9A" />
                                                                                <Text whiteSpace={"nowrap"} fontSize={14} color="#9A9A9A" >{e.messages?.length} Messages</Text>
                                                                                <IconButton size={"sm"} border={"1px solid #444444"}  rounded={"full"} aria-label="" icon={<SparklesIcon size={16} />} />
                                                                            </Flex>
                                                                        </Flex> : <Flex w={"full"} justifyContent={"space-between"} alignItems={"center"} gap={2}>
                                                                            <Flex gap={2} flex={1}>
                                                                                <FaWhatsapp size={20} color="#9A9A9A" />
                                                                                <Text whiteSpace={"nowrap"} fontSize={14} color="#9A9A9A" >{e.messages?.length} Messages</Text>
                                                                            </Flex>
                                                                            <IconButton size={"sm"} border={"1px solid #444444"} rounded={"full"} aria-label="" icon={<SparklesIcon size={16} />} />
                                                                        </Flex>}
    
                                                                    </Flex>
                                                                    <AccordionIcon ml={2} />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel mt={1} w={'40vw'} pb={4} pr={0}>
                                                                {(e.messages).map((message: any) => {
                                                                    return ( message.role === "ai" ? <Stack mb={3} pb={3} >
                                                                            <Flex w={0.8} gap={2}>
                                                                                {/* <GradientBox borderRadius={2} left={-0.5} top={-0.5} w={8} height={7}> */}
                                                                                    <Avatar size={"sm"} src={e.agent?.profile_pic} name={e.agent?.name} />
                                                                                {/* </GradientBox> */}
                                                                                <Text>
                                                                                    {message.content}
                                                                                </Text>
                                                                            </Flex>
                                                                            <Text ml={8} whiteSpace={"nowrap"} fontSize={"sm"} color="#c2c2c2">
                                                                                {moment(message?.timestamp).tz("Asia/Kolkata").format("HH:mm A | DD MMMM")}
                                                                            </Text>
                                                                            <Box position='relative'>
                                                                                <Divider />
                                                                            </Box>
                                                                        </Stack> : <Stack gap={0} textAlign={"end"} alignItems={"end"} mb={3} pb={3} >
                                                                                <Flex direction={"row-reverse"} gap={2}>
                                                                                    {/* <Avatar src={agents[0].image} boxSize={8} borderRadius={2} /> */}
                                                                                    <Box>
                                                                                        <Collapse startingHeight={72} in={show}>
                                                                                        {message.content}
                                                                                        </Collapse>
                                                                                        <Box cursor={"pointer"} onClick={() => {
                                                                                            setShow(!show)
                                                                                        }} zIndex={2} pos={"relative"} mt={show ? 0 : -8} w={"full"} height={show ? 2 : 8} background={show ? "transparent" : "linear-gradient(to top, #FAFAFA 10%, transparent)"}></Box>
                                                                                    </Box>
                                                                                </Flex>
                                                                                    <Text whiteSpace={"nowrap"} fontSize={"sm"} color="#c2c2c2">
                                                                                        {moment(message?.timestamp).tz("Asia/Kolkata").format("HH:mm A | DD MMMM")}
                                                                                    </Text>
                                                                                    <Box position='relative'>
                                                                                    <Divider />
                                                                                </Box>
                                                                            </Stack>
                                                                    )
                                                                })}
                                                            </AccordionPanel>
                                                        </>)}
                                                </AccordionItem>
                                            </Accordion> : <></>}
                                                {/* backgroundImage={`linear-gradient(to top, ${theme.colors!.primary.hex} 10%, ${theme.colors!.tertiary.hex})`} */}
                                                
                                            </Stack>
                                        </Box>
                                        <StepSeparator />
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </Stack>
                </Flex>
        </Stack>
        </Page>
    )
}