import {
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Select,
  Skeleton,
  SkeletonText,
  Stack,
  Table,
  TableContainer,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import Page from "../../@components/Page";
import { RefObject, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import _ from "lodash";
import { getVoicesList } from "../../lib/app/voice-library/thunk";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ClockIcon,
  DownloadCloudIcon,
  EllipsisIcon,
  GlobeIcon,
  MessageCircleIcon,
  MessageSquareIcon,
  PauseIcon,
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
  PlayIcon,
  Sparkles,
  SparklesIcon,
} from "lucide-react";
import CircleIcon from "../../@components/CircleIcon";
import moment from "moment-timezone";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { useNavigate } from "react-router-dom";
import { getAllLogs } from "../../lib/app/logs/thunk";
import { Field, Form, Formik } from "formik";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Badge } from "~/components/ui/badge";
import Markdown from "react-markdown";

interface AssitantLogProps {
  id: string;
}

function TableAudio({
  url,
  onInteraction,
}: {
  url: string;
  onInteraction: (
    state: "playing" | "paused",
    ref?: RefObject<HTMLAudioElement> | null
  ) => void;
}) {
  const [duration, setDuration] = useState(0);
  const [track, setTrack] = useState(0);
  const [isPaused, setPaused] = useState(true);
  const ref = useRef<HTMLAudioElement>(null);

  return (
    <Flex alignItems={"center"} gap={3}>
      <audio
        onEnded={() => {
          onInteraction("paused", ref);
          setTrack(0);
          setPaused(true);
        }}
        onTimeUpdate={(e) => {
          setTrack(Math.ceil(e.currentTarget.currentTime));
        }}
        hidden
        ref={ref}
        src={url}
        onLoadedMetadata={(e) => {
          setDuration(Math.ceil(ref.current?.duration || 0));
        }}
      />

      {/* <CircularProgress
        max={duration}
        value={track}
        color="hsl(var(--primary))"
        size={8}
      >
        <CircularProgressLabel mx={"auto"}>
          
        </CircularProgressLabel>
      </CircularProgress> */}
      <IconButton
        colorScheme="black"
        bg={"black"}
        size={"sm"}
        rounded={"full"}
        aria-label=""
        icon={
          isPaused ? (
            <PlayIcon
              size={16}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
              fill="white"
              strokeWidth={0}
            />
          ) : (
            <PauseIcon
              color="hsl(var(--primary))"
              size={16}
              fill="white"
              strokeWidth={0}
              style={{ marginLeft: "auto", marginRight: "auto" }}
              cursor={"pointer"}
            />
          )
        }
        onClick={() => {
          if (ref.current?.paused) {
            ref.current?.play();
            setPaused(false);
          } else {
            ref.current?.pause();
            setPaused(true);
          }
        }}
      />
      {/* <Slider max={duration} value={track} min={0} colorScheme="primary" defaultValue={0}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider> */}
    </Flex>
  );
}

function Collapsible ({text} : { text: string }) {
  const [show, setShow] = useState(false)

  const handleToggle = () => setShow(!show)

  return (
    <>
    <Collapse startingHeight={36} in={show}>
    <Markdown className={"text-sm"}>{text}</Markdown>
    {/* <Text fontSize={"sm"} whiteSpace={"break-spaces"}>
    
    </Text> */}
      </Collapse>
      <Flex py={2} className="text-muted-foreground" alignItems={"center"} justifyContent={"center"} w={"full"} textAlign={"center"} gap={2} fontSize={"sm"} onClick={handleToggle}>
        <Text fontSize={"xs"} fontWeight={"medium"}>Show {show ? 'Less' : 'More'}</Text> <ChevronDownIcon size={12} />
      </Flex>
      </>
  )
}

export default function AssistantLogs(props: AssitantLogProps) {
  const voices = useAppSelector((state) => state.app.voices.list);
  const metadata = useAppSelector((state) => state.app.voices.list.metadata);
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(15);
  const toast = useToast();
  const organization = useAppSelector((state) => state.app.organizations.get);
  const logs = useAppSelector((state) => state.app.logs.list);
  const [selectedDates, setSelectedDates] = useState([
    moment().startOf("month").toDate(),
    moment().endOf("year").toDate(),
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    // if (organization.data?.id && props.id) {
      dispatch(getAllLogs(organization.data?.id, props.id));
    // }
  }, [props.id, organization.data?.id]);

  return (
    <Stack>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={8}>
            <Formik
              onSubmit={(values) => {
                toast({
                  title: "Feedback submitted",
                  status: "success",
                });
                onClose();
              }}
              initialValues={{
                name: "",
                description: "",
              }}
            >
              {({ errors, touched, values }) => (
                <Form
                  style={{
                    width: "100%",
                  }}
                >
                  <FormControl isInvalid={!!errors.name && touched.name}>
                    <FormLabel fontSize={"sm"}>
                      Enter your feedback{" "}
                      <Tooltip hasArrow label="info icon">
                        <InfoOutlineIcon cursor={"pointer"} />
                      </Tooltip>
                    </FormLabel>
                    <Field
                      name="name"
                      value={values.name}
                      rows={4}
                      as={Textarea}
                      validate={(v: string) => {
                        let error;
                        if (!v) {
                          error = "Enter a message";
                        }
                        return error;
                      }}
                      fontSize={"sm"}
                      placeholder="Please enter a message"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <Flex mt={2}>
                    <Button colorScheme={"primary"} size={"sm"} type="submit">
                      Submit
                    </Button>
                  </Flex>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>

      {logs.data?.length ? (
        logs.data.map((d: any) => (
          <Stack className="border-b last:border-b-0 pb-2">
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Stack gap={1}>
                <Flex mt={2} gap={2} flexWrap={"wrap"}>
                  <Badge
                    variant={
                      d.meta?.type === "webCall" ? "orange-subtle" : "primary-subtle"
                    }
                  >
                    {d.meta?.type === "webCall" ? (
                      <>
                        {" "}
                        <GlobeIcon className="mr-2" size={10} />
                        Web
                      </>
                    ) : (
                      <>
                        {" "}
                        <PhoneIcon className="mr-2" size={10} />
                        Call
                      </>
                    )}
                  </Badge>
                 { d.meta?.type !== "webCall" ? <Badge
                    variant={
                      d.meta?.type === "outboundPhoneCall"
                        ? "green-subtle"
                        : "violet-subtle"
                    }
                  >
                    {d.meta?.type === "outboundPhoneCall" ? (
                      <>
                        {" "}
                        <PhoneOutgoingIcon className="mr-2" size={10} />
                        Outbound
                      </>
                    ) : (
                      <>
                        {" "}
                        <PhoneIncomingIcon className="mr-2" size={10} />
                        Inbound
                      </>
                    )}
                  </Badge> : <></>}
                  {/* <Tag colorScheme="purple" variant={"solid"} size={"sm"}>
                          <TagLabel>${Number(d.cost || 0).toFixed(2)}</TagLabel>
                        </Tag> */}
                </Flex>
                <Text fontSize={"sm"} className="text-muted-foreground">
                {moment(typeof d.meta?.startedAt === "number" 
    ? new Date(d.meta.startedAt * 1000) 
    : new Date(d.meta?.startedAt))
    .format("DD MMM, hh:mm a")}
                </Text>
              </Stack>
              <TableAudio
                onInteraction={() => {}}
                url={d.meta?.stereoRecordingUrl || d.meta?.recordingUrl}
              />
            </Flex>
            <Box className={"bg-indigo-50/50 border border-indigo-300"} py={2} px={4} borderRadius={8}>
              <Text mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"} fontSize={"sm"} fontWeight={"semibold"}>
                Call Analysis: {d?.summary ? <Text ml={"auto"} as={"span"} fontSize={"xs"} className="text-purple-500">ESL AI <Sparkles style={{
                  display: "inline"
                }} size={10} /></Text> : <></>}
              </Text>
              {d?.summary ? <Collapsible text={d?.summary} /> : <Text fontSize={"sm"}>No summary found</Text>}
            </Box>
          </Stack>
        ))
      ) : (
        <></>
      )}
      {logs.state === "loading" ? (
        <Stack gap={4}>
          {[...Array(6)].map((e) => (
            <Skeleton h={16} borderRadius={10} />
          ))}
        </Stack>
      ) : (
        <></>
      )}
      {logs.data?.length === 0 && logs.state === "success" ? (
        <Center height={"50vh"}>
          <Text fontWeight={"semibold"} px={2}>
            No logs found
          </Text>
        </Center>
      ) : (
        <></>
      )}
      <Flex ml={2} justifyContent={"space-between"}>
      <Flex align={"center"} gap={2}>
        <Text whiteSpace={"nowrap"} fontSize={"small"}>
          
          Showing{" "}
          {metadata?.total_count < page * size
            ? metadata?.total_count
            : page * size}{" "}
          of {metadata?.total_count}
        </Text>
        <Select
          onChange={(v) => {
            setSize(Number.parseInt(v.target.value, 10));
          }}
          defaultValue={size}
          size="sm"
          borderRadius={8}
        >
          <option value={15} selected>
            15
          </option>
          <option value={30}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Select>
      </Flex>
      <Flex gap={2} align={"center"}>
        <IconButton
          isDisabled={page === 1}
          onClick={() => {
            setPage(1);
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronsLeftIcon size={16} />}
          aria-label=""
        />
        <IconButton
          isDisabled={page === 1}
          onClick={() => {
            setPage(page - 1);
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronLeftIcon size={16} />}
          aria-label=""
        />
        <IconButton
          // @ts-ignore
          isDisabled={metadata?.total_count <= page * size}
          onClick={() => {
            setPage(page + 1);
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronRightIcon size={16} />}
          aria-label=""
        />
        <IconButton
          // @ts-ignore
          isDisabled={metadata?.total_count <= page * size}
          onClick={() => {
            // @ts-ignore
            setPage(Math.ceil(metadata?.total_count / size));
          }}
          rounded={"full"}
          variant={"outline"}
          icon={<ChevronsRightIcon size={16} />}
          aria-label=""
        />
      </Flex>
    </Flex>
    </Stack>
  );
}
