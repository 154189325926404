/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, AlertTitle, Box, Flex, FormControl, FormErrorMessage, FormLabel, Heading, IconButton, Img, InputGroup, InputRightElement, Link, Stack, Text, useBoolean } from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EyeIcon, EyeOffIcon, MailIcon } from "lucide-react";
import GoogleIcon from '~/assets/img/google.svg'
import MicrosoftIcon from '~/assets/img/microsoft.svg'
import { initiateEmailLogin, initiateGoogleLogin, initiateMicrosoftLogin } from "../../lib/auth/login/thunk";
import { useAppDispatch, useAppSelector } from "../../lib/hooks";
import { resetState, setLoginError } from "../../lib/auth/login/actions";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { MarqueeDemoVertical } from "./Reviews";
import { useGoogleLogin } from "@react-oauth/google";
import MicrosoftLogin from "react-microsoft-login";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "~/utils/constants.loader";

export default function Login () {
    const dispatch = useAppDispatch();
    const state = useAppSelector(state => state.auth.login.login.state)
    const error = useAppSelector(state => state.auth.login.login.error)
    const [isVisible, { toggle: toggleVisibility }] = useBoolean(false)
    const navigate = useNavigate()
    const [mode, setMode] = useState<string | null>()

    const login = useGoogleLogin({
        onSuccess: tokenResponse => {
            dispatch(initiateGoogleLogin(tokenResponse.code, () => {
                setMode(null)
            }))
        },
        onError: () => {
            setMode(null)
        },
        flow: "auth-code",
        ux_mode: "popup"
    });

    const authHandler = (err: any, data: any) => {
       if (err) {
        dispatch(setLoginError(err.toString()))
       } else {
        setMode("outlook")
        dispatch(initiateMicrosoftLogin(data.idToken))
       }
    };

    useEffect(() => {
        if (state === "success") {
           setTimeout(() => {
            navigate("/")
           }, 2000)
        }
    }, [ state ])

    return (
        <Flex direction={"row-reverse"} justifyContent={"space-between"}>
             <Box flex={[0, 0.5]} height={"100vh"} className="border-l" overflow={"hidden"}>
             <MarqueeDemoVertical />
             </Box>
            <Stack py={6} alignItems={"center"} justifyContent={"space-between"} height={"100vh"} flex={[1, 0.5]}>
                <Box></Box>
                <Stack justifyContent={"center"} flex={1} alignItems={"center"} gap={4} borderRadius={8} py={8} px={[2, 12]} w={['80vw', '35vw']}>
                    <Stack w={"full"}>
                        <Heading w={"full"}  fontWeight={"black"} variant={"h1"}>Build your AI Team</Heading>
                        <Box h={2.5} background={"hsl(var(--primary))"} w={16}></Box>
                        <Text>Get started with your email below</Text>
                    </Stack>
                    {error.length ? <Alert py={2} mb={3} fontWeight={"medium"} borderRadius={6} variant={"solid"} status="error">
                        <AlertTitle>Invalid credentials</AlertTitle>
                    </Alert> : <></> }
                    <Formik onSubmit={(values) => {
                        setMode("email")
                        dispatch(initiateEmailLogin(values.email, values.password))
                        mixpanel.track(MIXPANEL_EVENTS.CLICK_ON_EMAIL_LOGIN, {
                            "email": values.email
                        })
                    }} initialValues={{
                        email: '',
                        password: ''
                    }}>
                        {({ errors, touched }) => (
                            <Form style={{
                                width: "100%"
                            }} onChange={() => {
                                dispatch(resetState())
                            }}>
                                <Stack gap={4} w={"full"}>
                                    <FormControl isInvalid={!!errors.email && touched.email}>
                                        <FormLabel fontSize={"sm"}>Email address</FormLabel>
                                        <Field fontSize="sm" validate={(value: any) => {
                                            let error;
                                            if (!value) {
                                                return "Email address is required"
                                            }
                                            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
                                               return "Invalid email address"
                                            }
                                            return error
                                        }} as={Input} name="email" rounded={6} placeholder="Enter your email address" type='email'  />
                                        <FormErrorMessage fontSize={"small"}>{errors.email}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={!!errors.password && touched.password}>
                                        <FormLabel fontSize={"sm"}>Password</FormLabel>
                                        <InputGroup>
                                            <Field fontSize="sm" validate={(value: any) => {
                                                let error;
                                                if (!value) {
                                                    error = "Password is required"
                                                }
                                                return error
                                            }} as={Input} name="password" rounded={6} placeholder="Enter password" type={isVisible ? 'text' : 'password'}  />
                                            <InputRightElement>
                                                <IconButton variant={"none"} size={"sm"} aria-label="" icon={isVisible ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />} onClick={toggleVisibility} />
                                            </InputRightElement>
                                        </InputGroup>
                                        <FormErrorMessage fontSize={"small"}>{errors.password}</FormErrorMessage>
                                    </FormControl>
                                    <Flex justifyContent={"end"}>
                                    <Text cursor={"pointer"} fontSize={"sm"} fontWeight={"semibold"} onClick={() => {
                                        navigate("/auth/forgot-password")
                                    }}  className="!underline">Forgot your password ?</Text>
                                    </Flex>
                                    <Box ml={1} mt={1}>
                                        <Text fontSize={"xs"} color={"#535353"}>By continuing, you agree to ESL’s <Text target="_blank" as={Link} href="https://electricspeech.co/privacy-policy" className="!underline">Terms of Service</Text> and <Text target="_blank" as={Link} href="https://electricspeech.co/privacy-policy" className="!underline">Privacy Policy</Text>.</Text>
                                    </Box>
                                    <Button className="w-full font-semibold" disabled={(state === "loading" || state === "success")} isLoading={mode === "email" && (state === "loading" || state === "success")} type="submit" variant={"dark"}> <MailIcon size={16} /> Continue with email</Button>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                    <Text fontWeight={"bold"} fontSize={"sm"} color={"gray"}>OR</Text>
                    <Button onClick={() => {
                        setMode("google")
                        dispatch(resetState())
                        login()
                    }} className="w-full font-semibold" disabled={(state === "loading" || state === "success")} isLoading={mode === "google" && (state === "loading" || state === "success")} type="button" variant={"outline"}>
                        <Img height={4} src={GoogleIcon} />
                        Continue with Google</Button>
                       <Box w={"100%"}>
                       <MicrosoftLogin className="w-full" redirectUri={`${window.location.origin}/auth/microsoft/callback`} graphScopes={["User.Read", "offline_access"]} clientId={"89d2a49b-1e2c-4932-9c86-bc6bca4d3783"} authCallback={authHandler} children={<Button disabled={(state === "loading" || state === "success")} className="w-full font-semibold" isLoading={mode === "outlook" && (state === "loading" || state === "success")} type="button" variant={"outline"}>
                        <Img height={4} src={MicrosoftIcon} />
                        Continue with Microsoft</Button>} /> 
                       </Box>
                        
                    {/* <Box w={"full"} position='relative' padding='5'>
                        <Divider />
                        <AbsoluteCenter fontSize={"small"} bg='white' px='4'>
                            OR
                        </AbsoluteCenter>
                    </Box>
                    <Button onClick={() => {
                        dispatch(initiateGoogleLogin())
                    }} isDisabled={true} isLoading={state === "loading" || state === "success"} fontWeight={"semibold"} alignItems={"center"} leftIcon={<Image verticalAlign={"middle"} src={GoogleIcon} boxSize={4} /> } variant={"outline"} rounded={8} w={"full"}>Continue with Google</Button> */}
                    <Text mt={4} fontSize={"sm"}>Don't have an account ? <Link href="/auth/signup" fontWeight={"semibold"}  textDecor={"underline"} color="hsl(var(--primary))">Create one</Link></Text>
                </Stack>
            </Stack>
        </Flex>
    )
}